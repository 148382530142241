import { render, staticRenderFns } from "./WanQingCloud.vue?vue&type=template&id=0923b670&scoped=true"
import script from "./WanQingCloud.vue?vue&type=script&lang=js"
export * from "./WanQingCloud.vue?vue&type=script&lang=js"
import style0 from "./WanQingCloud.vue?vue&type=style&index=0&id=0923b670&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0923b670",
  null
  
)

export default component.exports